<template>
  <div class="analytics-container">
    <div class="container">
      <div class="filters_container">
        <label>
          <span>from: </span>
          <input v-model="fromDate" type="date" />
        </label>
        <label>
          <span>till: </span>
          <input v-model="tillDate" type="date" />
        </label>
      </div>
      <table class="striped">
        <thead>
          <tr>
            <th class="center-align">Name</th>
            <th class="center-align">Count</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="{ even: idx % 2 }"
            v-for="(value, key, idx) in serializeAlanytics"
            :key="key"
          >
            <td class="center-align">{{ key }}</td>
            <td class="center-align">{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  name: "PILCatalogAnalytics",
  apollo: {
    alanyticsObj: {
      variables() {
        return {
          _gte: this.fromDate,
          _lte: this.tillDate
        };
      },
      result({ data: { data_analytics } }) {
        this.alanyticsObj = data_analytics;
      },
      context: {
        headers: {
          "x-hasura-role": "editor"
        }
      },
      query: gql`
        query getLogAnalitycsByPeriod($_gte: date = "", $_lte: date = "") {
          data_analytics(where: { date: { _gte: $_gte, _lte: $_lte } }) {
            data
            date
          }
        }
      `
    }
  },
  computed: {
    serializeAlanytics() {
      return this.alanyticsObj.reduce((acc, i) => {
        Object.entries(i.data).forEach(([key, value]) => {
          if (acc[key]) acc[key] += value;
          else acc[key] = value;
        });
        return acc;
      }, {});
    }
  },
  data() {
    return {
      fromDate: "",
      tillDate: "",
      alanyticsObj: []
    };
  },
  created() {
    this.setFirstDate();
  },
  methods: {
    setFirstDate() {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      const year = yesterday.getFullYear();
      let month = yesterday.getMonth() + 1;
      let day = yesterday.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      this.fromDate = `${year}-${month}-${day}`;
      this.tillDate = `${year}-${month}-${day}`;
    }
  }
};
</script>

<style scoped>
.striped {
  max-width: 600px;
  margin: auto;
}
.filters_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 500px;
  margin: 20px auto;
}
.filters_container label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}
.filters_container label span {
  text-align: center;
  color: black;
}
.filters_container input {
  margin-top: 10px;
  background: #f8f8f5;
  border: none;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 150px;
  height: 35px;
  margin-bottom: 0;
}
.datepicker-modal {
  max-width: 350px;
}
.datepicker-date-display {
  display: none;
}
.center-align {
  text-align: center;
  font-size: 12px;
  padding: 10px 20px;
}
.even {
  background: #f8f8f5;
}
.table-row {
}
</style>
